import { ArrowRightIcon, ArrowUturnLeftIcon, BuildingStorefrontIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import { noLogo } from "src/config/host";
import { objectToFormData } from "src/helpers";
import Button from "../Shared/Buttons/Button";
import Checkbox from "../Shared/Forms/Inputs/Checkbox";
import Input from "../Shared/Forms/Inputs/Input";

const AccountInformationForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const allowedExts = ["jpg", "jpeg", "png", "gif", "svg"];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onImageChange = (e, key) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        onHandleChange(key, e.target.files[0]);
      }
    }
  };

  const ImageHandler = ({ imageKey, defaultIcon = { icon: <UserCircleIcon className="h-8 w-8 stroke-2 text-gray-500" /> } }) => {
    const imageSource = useMemo(() => {
      const image = formData?.[imageKey];
      return image instanceof File ? URL.createObjectURL(image) : "";
    }, [imageKey]);

    return (
      <div className={`group relative flex items-center gap-4`}>
        <div className={`flex h-16 w-16 cursor-pointer items-center justify-center overflow-hidden rounded-full border border-gray-200 bg-white`}>
          {imageSource ? (
            <img
              src={imageSource}
              alt="Logo"
              className="h-full w-full object-cover"
              onError={(e) => (e.target.src = noLogo)}
            />
          ) : (
            defaultIcon?.icon
          )}
        </div>
        <div className="flex-shrink-0">
          <Button
            version="gray"
            className="relative !h-auto overflow-hidden py-3">
            <span className="text-sm font-semibold text-gray-700">Change</span>
            <input
              type="file"
              className="absolute right-0 top-0 z-10 h-full w-96 cursor-pointer opacity-0"
              onChange={(e) => onImageChange(e, imageKey)}
            />
          </Button>
        </div>
      </div>
    );
  };

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const body = {
        ...formData,
        plan: formData?.plan?._id,
      };

      await apiRequest(
        "POST",
        `/basic-data/validation`,
        {
          body: objectToFormData(body),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setView("choosePlan");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="my-4 space-y-5">
      <Button
        version="secondary"
        disabled={isSubmitting}
        onClick={() => setView("accountSetupForm")}>
        <ArrowUturnLeftIcon className="h-6 w-6" />
        Go back
      </Button>
      <div className="space-y-10">
        <div>
          <div className="flex-shrink gap-y-2 pb-2">
            <div className="flex pb-2 text-sm font-semibold uppercase leading-none text-gray-400 md:text-[10px]">Photo</div>
            <ImageHandler imageKey="image" />
          </div>
          <div className="w-full">
            <Input
              inline={true}
              label={"Your name"}
              wrapperClass={"bg-white"}
              placeholder={"John Doe"}
              value={formData?.name}
              onChange={(e) => onHandleChange("name", e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="flex-shrink gap-y-2 pb-2">
            <div className="flex pb-2 text-sm font-semibold uppercase leading-none text-gray-400 md:text-[10px]">Company Logo</div>
            <ImageHandler
              imageKey="company_image"
              defaultIcon={{ icon: <BuildingStorefrontIcon className="h-8 w-8 stroke-2 text-gray-500" /> }}
            />
          </div>
          <div className="relative">
            <Input
              inline={true}
              label={"Your company's name"}
              wrapperClass={"bg-white"}
              placeholder={"Google Inc."}
              value={formData?.company_name}
              onChange={(e) => onHandleChange("company_name", e.target.value)}
            />
          </div>
        </div>
        <div className="grid gap-y-3">
          <div className="relative">
            <div className="flex items-start gap-3">
              <span className="pt-1">
                <Checkbox
                  isChecked={formData?.terms_condition}
                  onChange={(e) => onHandleChange("terms_condition", e.target.checked)}
                />
              </span>
              <span className="text-base text-gray-500">
                I agree with ClearSquare's product{" "}
                <a
                  href="https://www.clearsquare.co/terms-of-service"
                  rel="noreferrer"
                  target="_blank"
                  className="text-highlightColor hover:underline">
                  terms of service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.clearsquare.co/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="text-highlightColor hover:underline">
                  privacy policy
                </a>
                .
              </span>
            </div>
          </div>
          <div className="relative flex items-center justify-center gap-2">
            <Button
              version="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              className="!w-full"
              onClick={onHandleSubmit}>
              Next <ArrowRightIcon className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInformationForm;
