import React from 'react';
import LazyImage from "../Shared/LazyImage";

const TrustedCompanies = () => {
  const companies = [
    { _id: 1, name: "Teebox Golf", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/image_logo-670d5bc918c46ce46c06eecb-0?1740859239808"},
    { _id: 2, name: "Home Franchise Concepts", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/site_logo-621326dcf57ea70d27d3d6b4-18?1740859132933"},
    { _id: 3, name: "Bispoke", logo:"https://bispoke.io/wp-content/uploads/2024/05/bispoke_logo_black_gradient-1.png"},
    { _id: 4, name: "Beehive Meals", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/image_logo-65a973b58ffc33f023acf03d-0?1740859183343"},
    { _id: 5, name: "Sunmar Healthcare", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/image_logo-66f2ef8fe76364b221ff5368-0?1740859209632"},
  ]
  return (
    <div className="space-y-6 pt-6">
      <div className="text-gray-400 font-medium text-lg">Our goal is to help <span className='font-semibold'>5000 analysts</span> build revenue generating analytics agencies from existing reports.</div>
      {companies?.length > 0 && (
        <div className="flex items-center flex-wrap gap-10">
          {companies?.map((item, index) => (
            <div className="h-8 w-auto flex" key={index}>
              <LazyImage src={item.logo} alt={item.name} classes={"object-contain w-full h-full grayscale"} effect={"blur"} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TrustedCompanies;